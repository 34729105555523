import { mapTheme } from './mapTheme';

export const mapConfigCenter = {
	lat: 50.01183083574955,
	lng: 36.220952684842594,
};

export const mapConfigOption = {
	fullscreenControl: false,
	scaleControl: false,
	streetViewControl: false,
	zoomControl: true,
	rotateControl: false,
	keyboardShortcuts: false,
	disableDoubleClickZoom: false,
	mapTypeControl: false,
	gestureHandling: 'cooperative',
	styles: mapTheme,
};
