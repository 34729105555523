let sreenWidth = () => {
    let w = window.innerWidth;
    let screenSize = 'large'
    if (w < 600) {
        screenSize = 'small'
    }
    if (w > 600 && w < 1100) {
        screenSize = 'medium'
    }
    return `${screenSize}`
}
const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`;

export let sreenWidthVar = sreenWidth()
export let imageSrc = (mainNews) => {
    if(mainNews) {
        return `${_baseUrl}${mainNews?.image[sreenWidthVar]?.path}`
    }
    return ''
}