import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '../hooks';

interface propsBurgerButton {
	className?: string,
	isOpenDropdown: boolean,
	openDropdown: () => void,
	closeDropdown: () => void,
}

function BurgerButton({ className, isOpenDropdown, openDropdown, closeDropdown }: propsBurgerButton) {

	const buttonRef = useRef<HTMLButtonElement>(null);
	const [isActive, setIsActive] = useState(isOpenDropdown);

	const toggleActiveState = () => {
		isActive ? setIsActive(false) : setIsActive(true);
		isActive ? closeDropdown() : openDropdown();
	}

	const handleInActive = () => {
		setIsActive(false);
	}

	useOnClickOutside(buttonRef, handleInActive);

	return (
		<button
			type="button"
			ref={buttonRef}
			onClick={() => toggleActiveState()}
			className={isActive ? `burger-button active ${className}` : `burger-button ${className}`}
		>
			<span></span>
			<span></span>
			<span></span>
		</button>
	);
};

export default BurgerButton;
