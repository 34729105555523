import React from 'react';
import iconFacebook from '../img/icons/social/facebook.svg';
import iconInstagram from '../img/icons/social/instagram.svg';
import iconYoutube from '../img/icons/social/youtube.svg';
import iconGoogle from '../img/icons/social/google.svg';

interface propsContactSocial {
	className?: string;
}

function ContactSocial({ className }: propsContactSocial) {
	return (
		<ul className={`contact-social ${className}`}>
			<li className="contact-social__item">
				<a
					href="https://www.facebook.com/Virtual-Design-Bureau-Ukraine-106887037789802"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconFacebook}
						alt="facebook"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.instagram.com/ukrhydroproject/"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconInstagram}
						alt="instagram"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.youtube.com/channel/UCGb_695qQu15YUXLPIn9yNg"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img
						src={iconYoutube}
						alt="youtube"
						className="contact-social__logo"
					/>
				</a>
			</li>
			<li className="contact-social__item">
				<a
					href="https://www.google.com/search?q=%D0%BC%D1%96%D0%B6%D0%B2%D1%96%D0%B4%D0%BE%D0%BC%D1%87%D0%B8%D0%B9+%D1%86%D0%B5%D0%BD%D1%82%D1%80+%D1%96%D0%BD%D0%B6%D0%B5%D0%BD%D0%B5%D1%80%D0%BD%D0%B8%D1%85+%D0%B4%D0%BE%D1%81%D0%BB%D1%96%D0%B4%D0%B6%D0%B5%D0%BD%D1%8C&sxsrf=APwXEdeXqUhYL1tBQACP0CHRgEAbG_cxLA%3A1687781633550&ei=AYGZZPuSIdHRqwGS07tI&ved=0ahUKEwi7l-fF9OD_AhXR6CoKHZLpDgkQ4dUDCA8&uact=5&oq=%D0%BC%D1%96%D0%B6%D0%B2%D1%96%D0%B4%D0%BE%D0%BC%D1%87%D0%B8%D0%B9+%D1%86%D0%B5%D0%BD%D1%82%D1%80+%D1%96%D0%BD%D0%B6%D0%B5%D0%BD%D0%B5%D1%80%D0%BD%D0%B8%D1%85+%D0%B4%D0%BE%D1%81%D0%BB%D1%96%D0%B4%D0%B6%D0%B5%D0%BD%D1%8C&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCCEQoAFKBAhBGABQAFgAYKYCaABwAHgAgAF4iAF4kgEDMC4xmAEAoAECoAEBwAEB&sclient=gws-wiz-serp"
					target="_blank"
					rel="noreferrer"
					className="contact-social__link"
				>
					<img src={iconGoogle} alt="google" className="contact-social__logo" />
				</a>
			</li>
		</ul>
	);
}

export default ContactSocial;
