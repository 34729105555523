import { useCallback } from 'react';

const useHttp = () => {
	const _baseUrl = `${process.env.REACT_APP_API_URL}/api`;

	const request = useCallback(
		async (
			url,
			method = 'GET',
			body = null,
			headers = { 'Content-Type': 'application/json' }
		) => {
			try {
				const response = await fetch(`${_baseUrl}${url}`, {
					method,
					body,
					headers,
				});

				if (!response.ok) {
					throw new Error(`Could not fetch ${url}, status: ${response.status}`);
				}

				const data = await response.json();
				return data;
			} catch (e) {
				throw e;
			}
		},
		[_baseUrl]
	);

	const getAll = useCallback(
		async (category: string, 
			method = 'GET',
			body = null,
			headers = { 'Content-Type': 'application/json' }) => {
			const data = await request(
				`/${category}/all`, method, body, headers
			);
			return  data;
		},
		[request]
	);

	const getAll100 = useCallback(
		async (category: string, 
			method = 'GET',
			body = null,
			headers = { 'Content-Type': 'application/json' }) => {
			const data = await request(
				`/${category}/all?perPage=100`, method, body, headers
			);
			return  data;
		},
		[request]
	);

	const getAllWithPagination = useCallback(
		async (
			category: string,
			method = 'GET',
			body = null,
			headers = { 'Content-Type': 'application/json' },
			page: number = 1
		) => {
			const data = await request(
				`/${category}/all?page=${page}&perPage=10`, method, body, headers
			);
			return data;
		},
		[request]
	);

	const getOne = useCallback(
		async (url: string,
		method = 'GET',
		body = null,
		headers = { 'Content-Type': 'application/json' }) => {
			const response = await request(url);
			return response;
		},
		[request]
	);

	const getPinned = useCallback(
		async (category: string,
		method = 'GET',
		body = null,
		headers = { 'Content-Type': 'application/json' }) => {
			const response = await request(`/${category}/pinned`,method, body, headers);
			return response;
		},
		[request]
	);

	const getAllDoc = useCallback(
		async (category: string, 
			method = 'GET',
			body = null,
			type = '',
			headers = { 'Content-Type': 'application/json' }) => {
			const data = await request(
				`/${category}/all?type=${type}&perPage=100`, method, body, headers
			);
			return  data;
		},
		[request]
	);

	const getSearch = useCallback(
		async (searchRequest: string, locale: string, page: number = 1, 
		method = 'GET',
		body = null,
		headers = { 'Content-Type': 'application/json' }) => {
			const response = await request(
				`/workspace/search?query=${searchRequest}&perPage=8&page=${page}`, method, body, headers
			);
			return response;
		},
		[request]
	);

	return {
		request,
		getAll,
		getAllWithPagination,
		getOne,
		getPinned,
		getSearch,
		getAllDoc,
		getAll100
	};
};

export default useHttp;
