import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsFooterMarketing {
	className?: string;
	children?: ReactNode;
}

function FooterMarketing({ className, children }: propsFooterMarketing) {
	const { t } = useTranslation();

	return (
		<div className={`footer-marketing ${className}`}>
			<div className="footer-marketing__body">
				{children}
				<ul className="footer-marketing__list">
					<li className="footer-marketing__item">
						<a href="tel:+380577021518" className="footer-marketing__link">
							+38 057 702-15-18
						</a>
					</li>
					<li className="footer-marketing__item">
						<a
							href="mailto:marketing@mcii.kharkov.ua"
							className="footer-marketing__link"
						>
							marketing@mcii.kharkov.ua
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default FooterMarketing;
