export const MAIN = '/';
export const ABOUT = `${MAIN}about`;
export const SERVICES = `${MAIN}services`;
export const EQUIPMENT = `${MAIN}equipment`;
export const NEWS = `${MAIN}news`;
export const VACANCIES = `${MAIN}vacancies`;
export const SEARCH = `${MAIN}search`;

const routes = [
	{
		path: MAIN,
		breadcrumb: 'Головна',
		breadcrumb_ua: 'Головна',
		breadcrumb_en: 'Main',
		breadcrumb_ru: 'Главная',
	},
	{
		path: ABOUT,
		breadcrumb: 'Про компанію',
		breadcrumb_ua: 'Про компанію',
		breadcrumb_en: 'About',
		breadcrumb_ru: 'О компании',
	},
	{
		path: SERVICES,
		breadcrumb: 'Послуги',
		breadcrumb_ua: 'Послуги',
		breadcrumb_en: 'Services',
		breadcrumb_ru: 'Услуги',
	},
	{
		path: EQUIPMENT,
		breadcrumb: 'Обладнання',
		breadcrumb_ua: 'Обладнання',
		breadcrumb_en: 'Equipment',
		breadcrumb_ru: 'Оборудование',
	},
	{
		path: NEWS,
		breadcrumb: 'Новини',
		breadcrumb_ua: 'Новини',
		breadcrumb_en: 'News',
		breadcrumb_ru: 'Новости',
	},
	{
		path: `${NEWS}/:id`,
		breadcrumb: 'Внутрішня новин',
	},
	{
		path: VACANCIES,
		breadcrumb: 'ВакансіЇ',
		breadcrumb_ua: 'ВакансіЇ',
		breadcrumb_en: 'Career',
		breadcrumb_ru: 'Вакансии',
	},
	{
		path: `${VACANCIES}/:id`,
		breadcrumb: 'Внутрішня вакансій',
	},
	{
		path: SEARCH,
		breadcrumb: 'Пошук',
		breadcrumb_ua: 'Пошук',
		breadcrumb_en: 'Search',
		breadcrumb_ru: 'Поиск',
	},
];

export default routes;
