import { useCallback } from "react";
import useHttp from "./httpService";

const useVacancies = () => {
	const { getAllWithPagination, getOne, getAll100 } = useHttp();

	const getAllVacancies = useCallback(
		(language, workspace) => getAll100('vacancy','GET', null,
		{'Accept-Language': `${language}`, 
		'X-Workspace': `${workspace}`,
		'Access-Control-Allow-Origin': '*'}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getAll100]
	);
	const getVacancy = useCallback(
		(vacancyId: string, language,) => getOne(`/vacancy/translation/${vacancyId}/get`, 'GET', null,
		{'Accept-Language': `${language}`, 
		// 'X-Workspace': `${workspace}`,
		'Access-Control-Allow-Origin': '*'}),
		[getOne]
	);

	const getAllVacanciesAtom = useCallback(
		() => getAllWithPagination("vacancy", "site/2"),
		[getAllWithPagination]
	);

	const getAllVacanciesUkrgidro = useCallback(
		() => getAllWithPagination("vacancy", "site/1"),
		[getAllWithPagination]
	);

	const getAllVacanciesGidro = useCallback(
		() => getAllWithPagination("vacancy", "site/3"),
		[getAllWithPagination]
	);

	return {
		getAllVacancies,
		getAllVacanciesAtom,
		getAllVacanciesUkrgidro,
		getAllVacanciesGidro,
		getVacancy,
	};
};

export default useVacancies;
