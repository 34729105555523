import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";

interface propsTitle {
	children: ReactNode;
	className?: string;
	size: "big" | "middle" | "small";
	weight?: "bold";
	uppercase?: boolean;
	onClick?: () => void;
}

function Title({
	children,
	className,
	size,
	weight,
	uppercase,
	onClick,
}: propsTitle) {
	let location = useLocation();
	
		 if (location.pathname === '/' || className === 'management__title' || className === 'about-file__title') {
			return (
			<h2
				className={`title title--${size} title--${weight} ${
					uppercase && "title--uppercase"
				} ${className}`}
				onClick={onClick}
			>
				{children}
			</h2>)
		} else {
			return (
			<h1
				className={`title title--${size} title--${weight} ${
					uppercase && "title--uppercase"
				} ${className}`}
				onClick={onClick}
			>
				{children}
			</h1>)
		}
}

export default Title;
