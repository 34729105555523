import React from 'react';
import iconPhone from '../img/icons/phone.svg';
import iconMail from '../img/icons/mail.svg';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsContactReception {
	className?: string;
}

function ContactReception({ className }: propsContactReception) {
	const { t } = useTranslation();

	return (
		<div className={`contact-reception ${className}`}>
			<h3 className="contact-reception__title">
				{t('footer.reception.title')}:
			</h3>
			<ul className="contact-reception__list">
				<li className="contact-reception__item">
					<img
						src={iconPhone}
						alt="phone icon"
						className="contact-reception__icon"
					/>
					<a href="tel:+380577021506">+38 057 702-15-06</a>
					{/* <a href="tel:+380577175705">717 57 05</a> */}
				</li>
				{/* <li className="contact-reception__item">
					<a href="tel+380577194730">
						<img
							src={iconPhone}
							alt="phone icon"
							className="contact-reception__icon"
						/>
						{t('footer.contact.fax')}: +38 057 719 47 30
					</a>
				</li> */}

				<li className="contact-reception__item">
					<a href="mailto:mcii@uhp.kharkov.ua">
						<img
							src={iconMail}
							alt="mail icon"
							className="contact-reception__icon"
						/>
						mcii@uhp.kharkov.ua
					</a>
				</li>
			</ul>
		</div>
	);
}

export default ContactReception;
