import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../hooks";
import BurgerButton from "./BurgerButton";
import ContactButton from "./ContactButton";
import ContactDropdown from "./ContactDropdown";

interface propContact {
	className?: string;
}

function Contact({ className }: propContact) {
	const [isOpenDropdown, setIsDropdownOpen] = useState(false);
	const refConctactDropdown = useRef<HTMLDivElement>(null);

	const handleOpenDropdown = () => {
		setIsDropdownOpen(true);
	};

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};

	useOnClickOutside(refConctactDropdown, handleCloseDropdown);

	return (
		<div ref={refConctactDropdown} className={`contact ${className}`}>
			<div className="contact__body">
				<ContactButton
					className="contact__button"
					openDropdown={handleOpenDropdown}
				/>
				<BurgerButton
					className="contact__burger-button"
					openDropdown={handleOpenDropdown}
					closeDropdown={handleCloseDropdown}
					isOpenDropdown={isOpenDropdown}
				/>
				<ContactDropdown
					className="contact__dropdown"
					isOpenDropdown={isOpenDropdown}
					closeDropdown={handleCloseDropdown}
				/>
			</div>
		</div>
	);
}

export default Contact;
