import { useSelector } from "react-redux";
import { stateType } from "../../typings/state";

const useStore = () => {
	const state = useSelector((state: stateType) => state);
	const vacancies = state.vacancies.vacancies;
	const news = state.news.news;
	const { documents, siteDocument } = state.documents;
	const language = state.language.language;
	const contactDropdown = state.contactDropdown.isOpen;
	const title = state.title.title

	return {
		vacancies,
		news,
		documents,
		siteDocument,
		language,
		contactDropdown,
		title
	};
};

export default useStore;
