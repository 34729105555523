import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { SERVICES } from '../helpers/routes';
import useStore from '../store/selectors/useStore';
import { getLocaleProperty } from '../utils/getLocaleProperty';

const serviceLinks = [
	{
		name_ua: 'Інженерно-геологічні вишукування',
		name_en: 'Engineering geological surveys',
		name_ru: 'Инженерно-геологические изыскания',
		path: SERVICES,
	},
	{
		name_ua: 'Інженерно-геодезичні вишукування',
		name_en: 'Engineering geodetic surveys',
		name_ru: 'Инженерно-геодезические изыскания',
		path: SERVICES,
	},
	{
		name_ua: 'Інженерно-гідрологічні вишукування',
		name_en: 'Engineering hydrological surveys',
		name_ru: 'Инженерно-гидрологические изыскания',
		path: SERVICES,
	},
];

interface propsFooterService {
	className?: string;
	children?: ReactNode;
}

function FooterService({ className, children }: propsFooterService) {
	const { language } = useStore();

	const handleClick = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	return (
		<div className={`footer-service ${className}`}>
			<div className="footer-service__body">
				{children}

				<ul className="footer-service__list">
					{serviceLinks.map((item, index) => (
						<li key={index} className="footer-service__item">
							<Link
								to={`${item.path}?type=${index}`}
								onClick={handleClick}
								className="footer-service__link"
							>
								{getLocaleProperty(item, 'name', language)}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default FooterService;
