import { combineReducers } from 'redux';
import documentsReducer from './slices/documentsSlice';
import newsReducer from './slices/newsSlice';
import vacanciesReducer from './slices/vacanciesSlice';
import languageReducer from './slices/languageSlice';
import contactDropdownReducer from './slices/contactDropdownSlice';
import titleReducer from './slices/titleSlice';

const rootReducer = combineReducers({
	vacancies: vacanciesReducer,
	news: newsReducer,
	documents: documentsReducer,
	language: languageReducer,
	contactDropdown: contactDropdownReducer,
	title: titleReducer
});

export default rootReducer;
