import React from 'react';
import Title from './Title';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import useStore from '../store/selectors/useStore';
import { Link } from 'react-router-dom';
import { SERVICES } from '../helpers/routes';

interface propsMainAboutInfo {
	className?: string;
}

function MainHeroAboutInfo({ className }: propsMainAboutInfo) {
	const { language } = useStore();
	const { t } = useTranslation();

	return (
		<div className={'main-hero-about-info ' + className}>
			<div className="main-hero-about-info__body">
				<div className="main-hero-about-info__title">
					{language !== 'en' ? (
						<>
							<span className="main-about-info__subtitle">
								{t('main.title.type')}
							</span>
							<h1 className="main-about-info__title">{t('main.title.name')}</h1>
						</>
					) : (
						<>
							<h1 className="main-about-info__title">{t('main.title.name')}</h1>
							<span className="main-about-info__subtitle">
								{t('main.title.type')}
							</span>
						</>
					)}
				</div>

				<div className="main-hero-about-info__description">
					{t('main.description')}
				</div>
				<Link to={SERVICES} className="main-hero-about-info__button">
					{t('main.buttons.to-services')}
				</Link>
			</div>
		</div>
	);
}

export default MainHeroAboutInfo;
