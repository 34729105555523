import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsMainHero {
	images: string[];
	className?: string;
}

const settings = {
	centeredSlides: true,
	speed: 500,
	watchSlidesProgress: true,
	autoplay: true,
	spaceBetween: 25,
	slidesPerView: 1,
	slideActiveClass: 'equipment-slider__slide-active',
	slidePrevClass: 'equipment-slider__slide-prev',
	slideNextClass: 'equipment-slider__slide-next',
	slideVisibleClass: 'equipment-slider__slide-visible',
	slideToClickedSlide: true,
	loop: true,
	navigation: {
		nextEl: '.equipment-slider__prev',
		prevEl: '.equipment-slider__next',
	},
	pagination: {
		clickable: true,
		bulletElement: 'span',
		clickableClass: 'bullets',
		bulletClass: 'bullets__bullet',
		bulletActiveClass: 'bullets__bullet--active',
	},
	breakpoints: {
		1365: {
			slidesPerView: 3,
		},
		646: {
			slidesPerView: 2,
		},
		350: {
			slidesPerView: 1,
		},
	},
};

function EquipmentSlider({ className, images }: propsMainHero) {
	const { t } = useTranslation();

	return (
		<div className={`${className}`}>
			<div className="equipment-slider__body">
				<Swiper
					{...settings}
					modules={[Pagination, Autoplay, Navigation]}
					className="equipment-slider__slider"
				>
					{images.map((image, index) => (
						<SwiperSlide key={index} className="equipment-slider__slide">
							<div className="equipment-slider__slide-body">
								<img src={image} alt="" />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
				<div className="equipment-slider__navigation">
					<button type="button" className="equipment-slider__next" />
					<button type="button" className="equipment-slider__prev" />
				</div>
			</div>
		</div>
	);
}

export default EquipmentSlider;
