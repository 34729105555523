import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

import MainHeroAboutInfo from './MainHeroAboutInfo';

import slide1 from '../img/mainHero/main-photo-1.jpg';
import slide2 from '../img/mainHero/main-photo-2.jpg';
import slide3 from '../img/mainHero/main-photo-3.jpg';
import slide4 from '../img/mainHero/main-photo-4.jpg';

const slides = [
	{ img: slide1 },
	{ img: slide2 },
	{ img: slide3 },
	{ img: slide4 },
];

interface propsMainHero {
	className?: string;
}

const settings = {
	speed: 500,
	watchSlidesProgress: true,
	autoplay: true,
	slidesPerView: 1,
	centeredSlides: false,
	loop: true,
	pagination: {
		clickable: true,
		bulletElement: 'main-hero__bullet',
		bulletClass: 'main-hero__bullet',
		bulletActiveClass: 'main-hero__bullet--active',
		clickableClass: 'main-hero__pagination',
	},
};

function MainHero({ className }: propsMainHero) {
	return (
		<section className={`container main-hero ${className}`}>
			<div className="main-hero__body main-hero__body--reverse-mobile">
				<div className="main-hero__decorative-line" />
				<MainHeroAboutInfo className="main-hero__left main-hero__left--padding-r" />
				<div className="main-hero__right ">
					<div className="main-hero__img main-hero__img--dashed-border main-hero__img--shadow">
						<Swiper
							{...settings}
							spaceBetween={0}
							modules={[Pagination, Autoplay]}
							className="main-hero__swiper"
						>
							{slides.map((slide, index) => (
								<SwiperSlide key={index}>
									<img src={slide.img} alt="" />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			</div>
		</section>
	);
}

export default MainHero;
