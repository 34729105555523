import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsFooterContact {
	className?: string;
	children?: ReactNode;
}

function FooterContact({ className, children }: propsFooterContact) {
	const { t } = useTranslation();

	return (
		<div className={`footer-contact ${className}`}>
			<div className="footer-contact__body">
				{children}
				<ul className="footer-contact__list">
					<li className="footer-contact__item">
						<address className="footer-contact__address">
							{t('footer.contact.address.first-part')}
							<br /> {t('footer.contact.address.second-part')}
						</address>
					</li>
					<li className="footer-contact__item">
						{t('footer.contact.telephone')}:{' '}
						<a href="tel:+380577021506" className="footer-contact__link">
						+38 057 702-15-06
						</a>
						{/* ;
						<a href="tel:+380577175705" className="footer-contact__link">
							717 57 05
						</a> */}
					</li>
					{/* <li className="footer-contact__item">
						<a href="tel:+380577194730" className="footer-contact__link">
							{t('footer.contact.fax')}: +38 057 719 47 30
						</a>
					</li> */}
					<li className="footer-contact__item">
						<a
							href="mailto:mcii@uhp.kharkov.ua"
							className="footer-contact__link"
						>
							E-mail: mcii@uhp.kharkov.ua
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default FooterContact;
