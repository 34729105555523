import React from 'react';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import MainHero from '../components/MainHero';
import MainManagement from '../components/MainManagement';
import MainNews from '../components/MainNews';
import MainStats from '../components/MainStats';
import MainAbout from '../components/MainAbout';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function Main() {
	const { t } = useTranslation();
	useDocumentTitle(`${t('main.title.type')} ${t('main.title.name')}`);

	return (
		<div className="main">
			<ScrollToTopOnMount />
			<div className="main__body">
				<MainHero className="main__hero" />
				<MainAbout />
				<MainStats />
				<MainNews className="main__news" />
				<MainManagement className="main__management" />
			</div>
		</div>
	);
}

export default Main;
