import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { EQUIPMENT } from '../helpers/routes';
import useStore from '../store/selectors/useStore';
import { getLocaleProperty } from '../utils/getLocaleProperty';

const equipmentLinks = [
	{
		name_ua: 'Геодезичні та гідрологічні інструменти',
		name_en: 'Geodetic and hydrological instruments',
		name_ru: 'Геодезические и гидрологические инструменты',
		path: EQUIPMENT,
	},
	{
		name_ua: 'Лабораторні прибори',
		name_en: 'Laboratory instruments',
		name_ru: 'Лабораторные приборы',
		path: EQUIPMENT,
	},
	{
		name_ua: 'Бурові установки',
		name_en: 'Drilling rigs',
		name_ru: 'Буровые установки',
		path: EQUIPMENT,
	},
	{
		name_ua: 'Моторні човни',
		name_en: 'Motor boats',
		name_ru: 'Моторные лодки',
		path: EQUIPMENT,
	},
];

interface propsFooterequipment {
	className?: string;
	children?: ReactNode;
}

function FooterEquipment({ className, children }: propsFooterequipment) {
	const { language } = useStore();

	const handleClick = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	return (
		<div className={`footer-equipment ${className}`}>
			<div className="footer-equipment__body">
				{children}
				<ul className="footer-equipment__list">
					{equipmentLinks.map((item, index) => (
						<li key={index} className="footer-equipment__item">
							<Link
								to={`${item.path}?type=${index}`}
								onClick={handleClick}
								className="footer-equipment__link"
							>
								{getLocaleProperty(item, 'name', language)}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default FooterEquipment;
