import React from 'react';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsAboutInfoText {
	className?: string;
}

function AboutInfoText({ className }: propsAboutInfoText) {
	const { t } = useTranslation();

	return (
		<div className={`about-info-text ${className}`}>
			<div className="about-info-text__wrapper">
				<div
					contentEditable="false"
					dangerouslySetInnerHTML={{
						__html: t('about.text'),
					}}
				/>
			</div>
		</div>
	);
}

export default AboutInfoText;
