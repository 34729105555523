import { propsMainNewsPriority } from '../typings/news';
import useStore from '../store/selectors/useStore';
import { NEWS } from '../helpers/routes';
import useRedirectToItem from '../hooks/useRedirectToItem';
import ButtonLink from './ButtonLink';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { imageSrc, sreenWidthVar } from '../helpers/imageSrc';

function MainNewsList({ className }: propsMainNewsPriority) {
	const { news, language } = useStore();
	const redirect = useRedirectToItem();
	const { t } = useTranslation();
	const _baseUrl = `${process.env.REACT_APP_API_URL}/storage/`;

	let displayedNewsAll = news?.filter((el, i) => {
		if (el?.article?.isPinned === 'false') {
			return el;
		}
	});

	let displayedNews = displayedNewsAll.filter((el, i) => {
		if (i < 3) {
			return el;
		}
	});

	return (
		<ul className={`main-news-list ${className}`}>
			{displayedNews.map((news) => (
				<li
					key={Math.random()}
					className="main-news-list__item"
					onClick={() => redirect(NEWS, news.slug)}
				>
					<div className="main-news-list__info">
						<span className="main-news-list__date">
							{news.article.updatedAt
								.slice(0, 10)
								.split('-')
								.reverse()
								.join('-')
								.replaceAll('-', '.')}
						</span>
						<h4 className="main-news-list__title">
							<div>{news.title}</div>
						</h4>
						<ButtonLink
							type="button"
							color="black"
							className="main-news-list__button"
							paddingBottom
						>
							{t('main.buttons.read-more')}
						</ButtonLink>
					</div>
					<div className="main-news-list__image">
						{news && <img src={`${imageSrc(news)}`} alt="" />}
					</div>
				</li>
			))}
		</ul>
	);
}

export default MainNewsList;
