import React, { ReactNode } from "react";

interface propsButtonLink {
	children: ReactNode;
	className?: string;
	onClick?: () => void;
	type?: "button" | "submit" | "reset";
	color: "blue" | "blue-light" | "white" | "black" | "red";
	paddingBottom?: boolean;
}

function ButtonLink({
	children,
	className,
	onClick,
	type,
	color,
	paddingBottom,
}: propsButtonLink) {
	return (
		<button
			type={type}
			className={`button-link button-link--${color} ${
				paddingBottom && "button-link--padding-b"
			} ${className}`}
			onClick={onClick}
		>
			{children}
		</button>
	);
}

export default ButtonLink;
