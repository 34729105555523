import React from 'react';
import { Link } from 'react-router-dom';
import Title from './Title';
import exampleImage1 from '../img/mainAbout/main-photo-2.png';
import { ABOUT } from '../helpers/routes';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';

function MainAbout() {
	const { t } = useTranslation();

	return (
		<section className="main-about">
			<div className="container">
				<div className="main-about__body">
					<div className="main-hero__left main-hero__left--about-mobile">
						<div className="main-hero__img main-about__img">
							<img src={exampleImage1} alt="exampleImage1" />
						</div>
					</div>
					<div className="main-hero__right main-hero__right--padding-l">
						<Title
							size="middle"
							uppercase
							className="main-hero-about-info__title"
						>
							{t('main.about.title')}
						</Title>
						<p className="main-about__info">{t('main.about.description')}</p>
						<Link to={ABOUT} className="main-about__button button-link">
							{t('main.buttons.read-more')}
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

export default MainAbout;
