import '../utils/i18next';
import { getLocaleProperty } from '../utils/getLocaleProperty';
import useStore from '../store/selectors/useStore';

interface propsList {
	className?: string;
	data: any[];
	type: 'equipment' | 'services';
}

function List({ className, data, type }: propsList) {
	const { language } = useStore();

	return type === 'equipment' ? (
		<ul className={`list ${className}`}>
			{data.map((item, index) => (
				<li key={index} className="list__item">
					<p className="list__name">
						{getLocaleProperty(item, 'name', language)}
					</p>
				</li>
			))}
		</ul>
	) : (
		<ol className={`list ${className}`}>
			{data.map((subItem, index) => (
				<li key={index} className="">
					<p className="list__sub-name list__sub-name--bold">
						{data.length !== 1 && (
							<>
								<span>
									{index + 1}
									{'. '}
								</span>
								<span>{getLocaleProperty(subItem, 'subTitle', language)}:</span>
							</>
						)}
					</p>
					<ul className={`list__sub-list ${className}`}>
						{subItem.subService.map((service, index) => (
							<li key={index} className="list__sub-item">
								<p className="list__sub-item-name">
									{getLocaleProperty(service, 'name', language)}
								</p>
							</li>
						))}
					</ul>
				</li>
			))}
		</ol>
	);
}

export default List;
