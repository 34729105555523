import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from './List';

import '../utils/i18next';
import { getLocaleProperty } from '../utils/getLocaleProperty';
import useStore from '../store/selectors/useStore';

interface propsTabs {
	className?: string;
	data: any[];
	type: 'equipment' | 'services';
	activeTab?: number | string;
}

function Tabs({ className, data, type, activeTab }: propsTabs) {
	const { language } = useStore();
	const [toggleState, setToggleState] = useState(Number(activeTab));
	const navigate = useNavigate();

	const handleClick = (index: number) => {
		setToggleState(index);
		navigate({
			pathname: window.location.pathname,
			search: `?type=${index}`,
		});
	};

	useEffect(() => {
		setToggleState(Number(activeTab));
	}, [activeTab]);

	return (
		<div className={`tabs ${className}`}>
			<div className="">
				<div className="tabs__body">
					<div
						className={`tabs__nav tabs__nav${type === 'equipment' ? '--equipment' : '--services'
							}`}
					>
						{data.map((tab, index) => (
							<button
								key={index}
								onClick={() => handleClick(index)}
								className={`tabs__nav-button 
								${toggleState === index ? ' active' : ''} 
								tabs__nav-button${type === 'equipment' ? '--equipment' : '--services'}`}
							>
								<span>{getLocaleProperty(tab, 'title', language)}</span>
							</button>
						))}
					</div>

					<ul className="tabs__content">
						{data.map((tab, index) => (
							<li
								key={index}
								className={
									toggleState === index
										? 'tabs__content-item active'
										: 'tabs__content-item'
								}
							>
								<List
									data={type === 'equipment' ? tab.equipment : tab.service}
									type={type}
									className="tabs__list"
								/>
							</li>
						))}
					</ul>

					{type === 'services' && (
						<div className="tabs__description">
							{getLocaleProperty(data[toggleState], 'description', language)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Tabs;
