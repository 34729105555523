import React, { useRef } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { mapConfigCenter, mapConfigOption } from '../helpers/mapConfig';
import iconMapMarker from '../img/icons/mapMarker.svg';
const MAP_API = process.env.REACT_APP_API_GOOGLE_MAP as string;

interface propsMap {
	className?: string;
}

function Map({ className }: propsMap) {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: MAP_API,
		language: 'uk',
	});

	const refMap = useRef(null);
	const onLoad = React.useCallback(function callback(map) {
		refMap.current = map;
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		refMap.current = null;
	}, []);

	return (
		<div className={`map ${className}`}>
			{isLoaded ? (
				<GoogleMap
					mapContainerClassName="map__container"
					center={mapConfigCenter}
					zoom={13}
					options={mapConfigOption}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					<Marker icon={iconMapMarker} position={mapConfigCenter} />
				</GoogleMap>
			) : (
				<div className="map__placeholder-loading" />
			)}
		</div>
	);
}

export default Map;
