import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Tabs from '../components/Tabs';
import Title from '../components/Title';

import { tabsService } from '../data/tabs';
import { useDocumentTitle } from '../hooks';

import '../utils/i18next';

interface propsServiceTabs {
	className?: string;
}

function Services({ className }: propsServiceTabs) {
	const { t } = useTranslation();
	useDocumentTitle(t('services.title'));

	const location = useLocation().search;
	const active = new URLSearchParams(location).get('type') || 0;

	const [activeTab, setActiveTab] = useState<number>(Number(active));

	useEffect(() => {
		setActiveTab(Number(active));
	}, [location, active]);

	return (
		<div className={`main-about-tabs ${className}`}>
			<ScrollToTopOnMount />
			<div className="container">
				<Title size="middle" uppercase className="main-about-tabs__title">
					{t('services.title')}
				</Title>
				<Tabs data={tabsService} type="services" activeTab={activeTab} />
			</div>
		</div>
	);
}

export default Services;
