import geodesy1 from '../img/equipment/geodesy/1.jpg';
import geodesy2 from '../img/equipment/geodesy/2.jpg';
import geodesy3 from '../img/equipment/geodesy/3.jpg';
import geodesy4 from '../img/equipment/geodesy/4.jpg';
import geodesy5 from '../img/equipment/geodesy/5.jpg';
import geodesy6 from '../img/equipment/geodesy/6.jpg';
import geodesy7 from '../img/equipment/geodesy/7.jpg';
import geodesy8 from '../img/equipment/geodesy/8.jpg';
import geodesy9 from '../img/equipment/geodesy/9.jpg';
import geodesy10 from '../img/equipment/geodesy/10.jpg';

import laboratory1 from '../img/equipment/laboratory/1.jpg';
import laboratory2 from '../img/equipment/laboratory/2.jpg';
import laboratory3 from '../img/equipment/laboratory/3.jpg';
import laboratory4 from '../img/equipment/laboratory/4.jpg';

import drilling1 from '../img/equipment/drilling/1.jpg';
import drilling2 from '../img/equipment/drilling/2.jpg';
import drilling3 from '../img/equipment/drilling/3.jpg';
import drilling4 from '../img/equipment/drilling/4.jpg';
import drilling5 from '../img/equipment/drilling/5.jpg';
import drilling6 from '../img/equipment/drilling/6.jpg';
import drilling7 from '../img/equipment/drilling/7.jpg';
import drilling8 from '../img/equipment/drilling/8.jpg';
import drilling9 from '../img/equipment/drilling/9.jpg';

import boats1 from '../img/equipment/boats/1.jpg';
import boats2 from '../img/equipment/boats/2.jpg';
import boats3 from '../img/equipment/boats/3.jpg';

export const tabsEquipment = [
	{
		title_ua: 'Геодезичне та гідрологічне обладнання',
		title_en: 'Geodhetic and hydrological equipment',
		title_ru: 'Геодезическое и гидрологическое оборудование',
		slider: [
			geodesy1,
			geodesy2,
			geodesy3,
			geodesy4,
			geodesy5,
			geodesy6,
			geodesy7,
			geodesy8,
			geodesy9,
			geodesy10,
		],
		equipment: [
			{
				name_ua: '3D сканер Leica RTC360',
				name_en: '3D scanner Leica RTC360',
				name_ru: '3D сканер Leica RTC360',
			},
			{
				name_ua: 'Нівелір Leica DNA03',
				name_en: 'Level Leica DNA03',
				name_ru: 'Нивелир Leica DNA03',
			},
			{
				name_ua: 'Ехолот HD-Lite',
				name_en: 'Echo depth sounder HD-Lite',
				name_ru: 'Эхолот HD-Lite',
			},
			{
				name_ua: 'Нівелір Leica LS15',
				name_en: 'Level Leica LS15',
				name_ru: 'Нивелир Leica LS15',
			},
			{
				name_ua: 'Квадрокоптер Phantom 4RTK',
				name_en: 'Quadcopter Phantom 4 RTK',
				name_ru: 'Квадрокоптер Phantom 4RTK',
			},
			{
				name_ua: 'Нівелір Topcon АТ-G1',
				name_en: 'Level Topcon АТ-G1',
				name_ru: 'Нивелир Topcon АТ-G1',
			},
			{
				name_ua: 'Тахеометри Topcon GTS-105N та ES-105',
				name_en: 'Total stations Topcon GTS-105N та ES-105',
				name_ru: 'Тахеометр Topcon GTS-105N та ES-105',
			},
			{
				name_ua: 'Нівелір Leica Jogger',
				name_en: 'Level Leica Jogger ',
				name_ru: 'Нивелир Leica Jogger',
			},
			{
				name_ua: 'Тахеометр Leica TS06 + R500',
				name_en: 'Total station Leica TS06 +R500',
				name_ru: 'Тахеометр Leica TS06 + R500',
			},
			{
				name_ua: 'Приймач GPS TRIMBLE R8s',
				name_en: 'GPS receiver TRIMBLE R8s',
				name_ru: 'Приемник GPS TRIMBLE R8s',
			},
			{
				name_ua: 'Тахеометр Leica TS06 R1000 5',
				name_en: 'Total station Leica TS06 R1000 5',
				name_ru: 'Тахеометр Leica TS06 R1000 5',
			},
			{
				name_ua: 'Приймач GPS Leica GPS GS08+',
				name_en: 'GPS receiver Leica GPS GS08+',
				name_ru: 'Приемник GPS Leica GPS GS08+',
			},
			{
				name_ua: 'Tахеометр Leica NS06 R1000 5',
				name_en: 'Total station Leica NS06 R1000 5',
				name_ru: 'Tахеометр Leica NS06 R1000 5',
			},
			{
				name_ua: 'Приймач GPS Leica GS07',
				name_en: 'GPS receiver Leica GS07',
				name_ru: 'Приемник GPS Leica GS07',
			},
			{
				name_ua: 'Вимірювач швидкості течії Valeport Model 106 та Model 102',
				name_en: 'Flow meters Valeport Model 106 and Model 102',
				name_ru: 'Измеритель скорости течения Valeport Model 106 та Model 102',
			},
			{
				name_ua: 'Приймач GPS Leica GS16',
				name_en: 'GPS receiver Leica GS16',
				name_ru: 'Приемник GPS Leica GS16',
			},
			{
				name_ua: 'Програмне забезпечення: Autodesk Civil 3D, Autodesk Recap, Pix4D Mapper, Leica Captivate, Leica Infinity, Leica Cyclone Field 360',
				name_en: 'Software: Autodesk Civil 3D, Autodesk Recap, Pix4D Mapper, Leica Captivate, Leica Infinity, Leica Cyclone Field 360',
				name_ru: 'Программное обеспечение: Autodesk Civil 3D, Autodesk Recap, Pix4D Mapper, Leica Captivate, Leica Infinity, Leica Cyclone Field 360',
			},
			{
				name_ua: 'Приймач GPS Leica GS18',
				name_en: 'GPS receiver Leica GS18',
				name_ru: 'Приемник GPS Leica GS18',
			},
		],
	},
	{
		title_ua: 'Лабораторні прилади',
		title_en: 'Laboratory equipment',
		title_ru: 'Лабораторные приборы',
		slider: [laboratory1, laboratory2, laboratory3, laboratory4],
		equipment: [
			{
				name_ua: 'Прилад тривісного стиску автоматизований ГТ1.3.5 (стабілометр) з камерою типу «А»',
				name_en: 'Triaxial compression automized device GT1.3.5 (stabilometer) with a type A camera',
				name_ru: 'Прибор трехосного сжатия автоматизированный ГТ 1.3.5 с камерой типа «А»',
			},
			{
				name_ua: 'Прилади для випробування на зсув',
				name_en: 'Shear testers',
				name_ru: 'Приборы для испытания на сдвиг',
			},
			{
				name_ua: 'Прилад для визначення межі пластичності методом розкочування ГТ 1.8.2 ',
				name_en: 'Device for determination of plastic limit by rolling method GT 1.8.2',
				name_ru: 'Прибор для определения границы  пластичности методом раскатывания ГТ 1.8.2',
			},
			{
				name_ua: 'Прилади для ущільнення ґрунтів перед зсувом',
				name_en: 'Devices for soil compaction before shear',
				name_ru: 'Приборы для уплотнения грунтов перед сдвигом',
			},
			{
				name_ua: 'Ваги лабораторні',
				name_en: 'Scales laboratory',
				name_ru: 'Весы лабораторные',
			},
			{
				name_ua: 'Прилад лабораторний ЛУС-100',
				name_en: 'Laboratory instrument LUS-100',
				name_ru: 'Лабораторный прибор ЛУС-100',
			},
			{
				name_ua: 'Компрес. прилади КПР',
				name_en: 'Compress. devices KPR',
				name_ru: 'Компресс. приборы КПР',
			},
			{
				name_ua: 'Прилад ПСГ-2м зсувовий №328',
				name_en: 'PSG-2m shear device No.328',
				name_ru: 'Прибор ПСГ-2м сдвиговый №328',
			},
			{
				name_ua: 'Компрессор Smart 50/20',
				name_en: 'Compressor Smart 50/20',
				name_ru: 'Компрессор Smart 50/20',
			},
			{
				name_ua: 'Прилад стандартного ущільнення ПСУ-ПА',
				name_en: 'Standard compaction device PSU-PA',
				name_ru: 'Прибор стандартного уплотнения ПСУ-ПА',
			},
			{
				name_ua: 'Млин МГ-1Ф',
				name_en: 'Mill MG-1F',
				name_ru: 'Мельница МГ-1Ф',
			},
			{
				name_ua: 'Прилад УПГС №128',
				name_en: 'Device UPGS No.128',
				name_ru: 'Прибор УПГС №128',
			},
			{
				name_ua: 'Прилад ВСВ- 25',
				name_en: 'Device VSV-25',
				name_ru: 'Прибор ВСВ-25',
			},
			{
				name_ua: 'Прилад фільтраційний КР-1',
				name_en: 'Filtration device KR-1',
				name_ru: 'Прибор фильтрационный КР-1',
			},
			{
				name_ua:
					'Прилад для визначення величини вільного набухання ґрунту ПН2',
				name_en:
					'Device for determination the amount of free swelling of the soil PN2',
				name_ru:
					'Прибор для определения величины свободного набухания грунта ПН2',
			},
		],
	},
	{
		title_ua: 'Бурові установки',
		title_en: 'Drilling rigs',
		title_ru: 'Буровые установки',
		slider: [
			drilling1,
			drilling2,
			drilling3,
			drilling4,
			drilling5,
			drilling6,
			drilling7,
			drilling8,
			drilling9
		],
		equipment: [
			{
				name_ua: 'Бурова установка Massenza MI-5 на базі Iveca Astra',
				name_en: 'Drilling rig Massenza MI-5 based on Iveca Astra',
				name_ru: 'Буровая установка Massenza MI-5 на базе Iveca Astra',
			},
			{
				name_ua: 'Бурова установка УГБ-1ВС на базі ЗІЛ-131',
				name_en: 'UGB-1VS drilling rig based on ZIL-131',
				name_ru: 'Буровая установка УГБ-1ВС на базе ЗИЛ-131',
			},
			{
				name_ua: 'Бурова установка УГБ-1ВС на базі ЗІЛ-131 з автопричепом',
				name_en: 'UGB-1VS drilling rig based on ZIL-131 with a trailer',
				name_ru: 'Буровая установка УГБ-1ВС на базе ЗИЛ-131 с автоприцепом',
			},
			{
				name_ua: 'Бурова установка СКБ-4на базі КАМАЗ-4310 з автопричепом',
				name_en: 'SKB-4 drilling rig based on KAMAZ-4310 with a trailer',
				name_ru: 'Буровая установка СКБ-4 на базе КАМАЗ-4310 с автоприцепом',
			},
			{
				name_ua: 'Бурова установка УКБ 200/300на базі ЗІЛ-131 з автопричепом',
				name_en: 'Drilling rig UKB 200/300 based on ZIL-131 with the trailer',
				name_ru: 'Буровая установка УКС 200/300 на базе ЗИЛ-131 с автоприцепом',
			},
			{
				name_ua: 'Бурова установка ПБУ-1на базі ЗІЛ-131',
				name_en: 'PBU-1 drilling rig based on ZIL-131',
				name_ru: 'Буровая установка ПБУ-1 на базе ЗИЛ-131',
			},
			{
				name_ua: 'Бурова установка МШК-15 та установча рама',
				name_en: 'MSHK-15 drilling rig and installation frame',
				name_ru: 'Буровая установка МШК-15 и установочная рама',
			},
		],
	},
	{
		title_ua: 'Моторні човни',
		title_en: 'Motor boats',
		title_ru: 'Моторные лодки',
		slider: [boats1, boats2, boats3],
		equipment: [
			{
				name_ua: 'Моторний човен Прогрес-4, (2 шт.)',
				name_en: 'Motor boat Progress-4, (2 pcs.)',
				name_ru: 'Моторная лодка Прогресс-4, (2 шт.)',
			},
			{
				name_ua: 'Причіп UMS BOAT 8902',
				name_en: 'UMS BOAT 8902 trailer',
				name_ru: 'Прицеп UMS BOAT 8902,',
			},
			{
				name_ua: 'Човен Colibri 6-містний',
				name_en: 'Boat Colibri 6-seater',
				name_ru: 'Лодка Colibri 6-местная',
			},
			{
				name_ua: 'Причіп V04214',
				name_en: 'Trailer V04214',
				name_ru: 'Прицеп V04214',
			},
		],
	},
];

export const tabsService = [
	{
		title_ua: 'Інженерно-геологічні вишукування',
		title_en: 'Engineering geological surveys',
		title_ru: 'Инженерно-геологические изыскания',
		description_ua:
			'ТОВ «МІЖВІДОМЧИЙ ЦЕНТР ІНЖЕНЕРНИХ ДОСЛІДЖЕНЬ» має дуже великий досвід з виконання зазначених вище робіт при реконструкції Дніпровського каскаду ГЕС та Дністровської ГЕС-1, будівництві Дністровської та Ташлицької ГАЕС, першочергових роботах по Канівській ГАЕС та Каховській ГЕС-2  та інших гідроенергетичних об’єктах в Україні. Крім того компанія має великий досвід роботи за кордоном в частині геологічного інженерного супроводу при проектуванні та будівництві гідроенергетичних об’єктів в Грузії, країнах колишнього СРСР, В’єтнамі, Ефіопії та ін.',
		description_en:
			'INTERDEPARTMENTAL CENTRE OF ENGINEERING RESEARCHES LTD. has large experience in performing all the works mentioned above at the rehabilitation of the Dnieper cascade of HPPs and Dniester HPP-1, construction of Dniester PSP and Tashlyk PSP, prior works on Kaniv PSP and Kakhovka HPP-2 and other hydro projects in Ukraine. Also the company has extensive experience abroad in the part of geological engineering support for the design and construction of hydropower facilities in Georgia, the former Soviet Union countries, Vietnam, Ethiopia and others.',
		description_ru:
			'ООО «МЕЖВЕДОМСТВЕННЫЙ ЦЕНТР ИНЖЕНЕРНЫХ ИЗЫСКАНИЙ» имеет очень большой опыт по выполнению указанных выше работ при реконструкции Днепровского каскада ГЭС и Днестровской ГЭС-1, строительстве Днестровской и Ташлыкской ГАЭС, первоочередных работах по Каневской ГЭС и Каховской ГЭС-2 и другим гидроэнергетическим объектам в Украине. Кроме того, компания имеет большой опыт работы за рубежом в части геологического инженерного сопровождения при проектировании и строительстве гидроэнергетических объектов в Грузии, странах бывшего СССР, Вьетнаме, Эфиопии и др.',
		service: [
			{
				subTitle_ua: 'Польові роботи',
				subTitle_en: 'Field works',
				subTitle_ru: 'Полевые работы',
				subService: [
					{
						name_ua:
							'Комплекс ударно-канатного буріння різного діаметру в сухих та обводнених ґрунтах в складних інженерно-геологічних умовах на глибини до 50 метрів.',
						name_en:
							'A complex of cable drilling of various diameters in dry and watered soils in difficult engineering and geological conditions up to 50 meters deep. ',
						name_ru:
							'Комплекс ударно-канатного бурения разного диаметра в сухих и обводненных грунтах в сложных инженерно-геологических условиях глубиной до 50 метров.',
					},
					{
						name_ua:
							'Комплекс колонкового буріння різного діаметру по скельним породам в складних інженерно-геологічних умовах на глибини до 80 метрів.',
						name_en:
							'A complex for core drilling of various diameters in rock in difficult engineering and geological conditions up to 80 meters deep. ',
						name_ru:
							'Комплекс колоночного бурения разного диаметра по скальным породам в сложных инженерно-геологических условиях глубиной до 80 метров.',
					},
					{
						name_ua:
							'Комплекс роторного та колонкового буріння різних діаметрів на буровій установці Massenza MI5 методикою ССК на глибини до 250 метрів.',
						name_en:
							'A complex of rotary and core drilling of various diameters on the Massenza MI5 drilling rig using the SSC method with a depth of up to 250 meters. ',
						name_ru:
							'Комплекс ударно-канатного бурения разного диаметра в сухих и обводненных грунтах в сложных инженерно-геологических условиях глубиной до 50 метров.',
					},
					{
						name_ua:
							'Колонкове буріння 3600 та шнекове буріння малогабаритними буровими установками у важкодоступних місцях та приміщеннях на глибини до 20 метрів.',
						name_en:
							'Core drilling 3600 and auger drilling with small-sized drilling rigs in inaccessible places and premises up to 20 meters deep',
						name_ru:
							'Колонковое бурение 3600 и шнековое бурение малогабаритными буровыми установками в труднодоступных местах и помещениях глубиной до 20 метров.',
					},
					{
						name_ua: 'Інженерно-геологічна зйомка території.',
						name_en: 'Engineering geological survey of the area.',
						name_ru: 'Инженерно-геологическая съемка местности.',
					},
					{
						name_ua:
							'Шурфування ґрунтів вручну та механічно на глибини до 10 метрів.',
						name_en:
							'Soil pitting manually and mechanically up to 10 meters deep. ',
						name_ru:
							'Шурфование грунтов вручную и механически глубиной до 10 метров.',
					},
					{
						name_ua: 'Відбір монолітів з шурфів та свердловин.',
						name_en: 'Selection of monoliths from pits and wells.',
						name_ru: 'Отбор монолитов из шурфов и скважин.',
					},
					{
						name_ua:
							'Комплекс інженерно-гідрогеологічних робіт у різних типах ґрунтів (наливи, відкачки). Виконання нагнітань у скельні масиви ґрунтів.',
						name_en:
							'A complex of engineering and hydrogeological works on different types of soils (filling, pumping). Implementation of injection into rock massifs of soil.',
						name_ru:
							'Комплекс инженерно-гидрогеологических работ на разных типах грунтов (наливы, откачки). Выполнение нагнетания в скальные массивы грунтов.',
					},
				],
			},
			{
				subTitle_ua: 'Лабораторні роботи',
				subTitle_en: 'Laboratory works',
				subTitle_ru: 'Лабораторные работы',
				subService: [
					{
						name_ua:
							'Повний комплекс визначень фізико-механічних властивостей зв’язних та незв’язних ґрунтів у власній сертифікованій лабораторії.',
						name_en:
							'A complete set of determinations of the physical and mechanical properties of cohesive and non-cohesive soils in own certified laboratory.',
						name_ru:
							'Полный комплекс определений физико-механических свойств связных и несвязанных почв в собственной сертифицированной лаборатории.',
					},
					{
						name_ua:
							'Визначення комплексу фізико-механічних властивостей ґрунтів у приладі 3-х осьового стиску.',
						name_en:
							'Determination of a complex of physical and mechanical properties of soils in a 3-axis compression device.',
						name_ru:
							'Определение комплекса физико-механических свойств грунтов в приборе 3-х осевого сжатия.',
					},
					{
						name_ua:
							'Визначення фізико-механічних характеристик скельних ґрунтів (керну).',
						name_en:
							'Determination of physical and mechanical characteristics of rocky soils (core).',
						name_ru:
							'Определение физико-механических характеристик скальных грунтов (керна).',
					},
					{
						name_ua: 'Хімічний аналіз води для інженерно-геологічних цілей.',
						name_en:
							'Chemical analysis of water for engineering-geological purposes.',
						name_ru:
							'Химический анализ воды для инженерно-геологических целей.',
					},
				],
			},
			{
				subTitle_ua: 'Камеральні роботи',
				subTitle_en: 'Cameral work',
				subTitle_ru: 'Камеральные работы',
				subService: [
					{
						name_ua:
							'Камеральне опрацювання польових матеріалів та написання звітів на базі програмного комплексу Autodesk.',
						name_en:
							'Office processing of field materials and reports preparation based on the Autodesk software package.',
						name_ru:
							'Камеральная обработка полевых материалов и написание отчетов на базе программного комплекса Autodesk.',
					},
					{
						name_ua: 'Робота з архівними матеріалами на базі власного архіву.',
						name_en: 'Work with archival materials based on own archive.',
						name_ru:
							'Работа с архивными материалами на основе собственного архива.',
					},
					{
						name_ua:
							'Побудова статичних інженерно-геологічних 3D-моделей для цілей проектування.',
						name_en:
							'Construction of static geotechnical 3D models for design purposes.',
						name_ru:
							'Построение статических инженерно-геологических 3D-моделей для целей проектирования.',
					},
				],
			},
		],
	},

	{
		title_ua: 'Інженерно-геодезичні вишукування',
		title_en: 'Engineering geodetic surveys',
		title_ru: 'Инженерно-геодезические изыскания',
		description_ua:
			'ТОВ «МІЖВІДОМЧИЙ ЦЕНТР ІНЖЕНЕРНИХ ДОСЛІДЖЕНЬ» має дуже великий досвід з виконання зазначених вище робіт при реконструкції Дніпровського каскаду ГЕС та Дністровської ГЕС-1, будівництві Дністровської та Ташлицької ГАЕС, першочергових роботах по Канівській ГАЕС та Каховській ГЕС-2  та інших гідроенергетичних об’єктах в Україні та за кордоном.',
		description_en:
			'INTERDEPARTMENTAL CENTRE OF ENGINEERING RESEARCHES LTD. has large experience in performing all the works mentioned above at the rehabilitation of the Dnieper cascade of HPPs and Dniester HPP-1, construction of Dniester PSP and Tashlyk PSP, prior works on Kaniv PSP and Kakhovka HPP-2 and other hydro projects in Ukraine and abroad.',
		description_ru:
			'ООО «МЕЖВЕДОМСТВЕННЫЙ ЦЕНТР ИНЖЕНЕРНЫХ ИЗЫСКАНИЙ» имеет очень большой опыт по выполнению указанных выше работ при реконструкции Днепровского каскада ГЭС и Днестровской ГЭС-1, строительстве Днестровской и Ташлыкской ГАЭС, первоочередных работах по Каневской ГЭС и Каховской ГЭС-2 и другим гидроэнергетическим объектам в Украине и за границей.',
		service: [
			{
				subTitle_ua: 'Інженерно-геодезичні вишукування',
				subTitle_en: 'Engineering geodetic surveys',
				subTitle_ru: 'Инженерно-геологические изыскания',
				subService: [
					{
						name_ua:
							"Спеціалісти компанії виконують інженерно-геодезичну зйомку будь-якої складності (включаючи промислові об'єкти та споруди) по всій території України від мінімальних площ до 1000 гектарів.",
						name_en:
							"The company's specialists perform engineering and geodetic surveys of any complexity (including industrial facilities and structures) throughout Ukraine from minimum areas to more than 1000 hectares.",
						name_ru:
							'Специалисты компании выполняют инженерно-геодезическую сьемку любой сложности (включая промышленные объекты и сооружения) по всей территории Украины от минимальных площадей до превышающих 1000 гектар.',
					},
					{
						name_ua:
							'Сучасне обладнання (тахеометри Leica серії TS07, GNSS-приймачі Leica серії GS18, квадрокоптер Phantom 4 RTK) та програмне забезпечення (Autodesk Civil 3D, Pix4D Mapper, Autodesk Recap, Leica Captivate, Leica Infinity) дозволяють якісно і в короткі терміни побудувати топографічний план та 3D-модель місцевості.',
						name_en:
							'Modern equipment (Leica TS07 series total stations, Leica GS18 series GNSS receivers, Phantom 4 RTK quadcopter) and software (Autodesk Civil 3D, Pix4D Mapper, Autodesk Recap, Leica Captivate, Leica Infinity) allow to build a topographic map and 3D terrain model.',
						name_ru:
							'Современное оборудование (тахеометры Leica серии TS07, GNSS-приемники Leica серии GS18, квадрокоптер Phantom 4 RTK) и программное обеспечение (Autodesk Civil 3D, Pix4D Mapper, Autodesk Recap, Leica Captivate, Leica Infinity) позволяютт качественно и в короткие сроки построить топографический план и 3D модель местности.',
					},
					{
						name_ua:
							'Повною мірою спеціалістами компанії ведуться як інженерно-геодезичні, так і контрольні натурні спостереження за будівництвом. Протягом року виконується понад 7000 спостережень за стійкістю контрольно-вимірювальної апаратури. Використовуючи високоточний цифровий нівелір Leica LS15, визначаються зміщення з точністю до 0.0001 метра.',
						name_en:
							"Entirely the company's specialists conduct both engineering and geodetic and control field observations over the construction. More than 7,000 observations of the stability of control and measuring equipment are performed throughout the year. Using a high-precision digital level Leica LS15, shifts are determined with an accuracy of 0.0001 meters.",
						name_ru:
							'В полной степени специалистами компании ведутся как инженерно-геодезические, так и контрольные натурные наблюдения за строительством. На протяжении года выполняются более 7000 наблюдений за устойчивостью контрольно-измерительной аппаратуры. Используя высокоточный цифровой нивелир Leica LS15, определяются смещения с точностью до 0.0001 метра.',
					},
				],
			},
		],
	},

	{
		title_ua: 'Інженерно-гідрологічні вишукування',
		title_en: 'Engineering hydrological surveys',
		title_ru: 'Инженерно-гидрологические изыскания',
		description_ua:
			'ТОВ «МІЖВІДОМЧИЙ ЦЕНТР ІНЖЕНЕРНИХ ДОСЛІДЖЕНЬ» має дуже великий досвід з виконання зазначених вище робіт при реконструкції Дніпровського каскаду ГЕС та Дністровської ГЕС-1, будівництві Дністровської та Ташлицької ГАЕС, першочергових роботах по Канівській ГАЕС та Каховській ГЕС-2  та інших гідроенергетичних об’єктах в Україні та за кордоном.',
		description_en:
			'INTERDEPARTMENTAL CENTRE OF ENGINEERING RESEARCHES LTD. has large experience in performing all the works mentioned above at the rehabilitation of the Dnieper cascade of HPPs and Dniester HPP-1, construction of Dniester PSP and Tashlyk PSP, prior works on Kaniv PSP and Kakhovka HPP-2 and other hydro projects in Ukraine and abroad.',
		description_ru:
			'ООО «МЕЖВЕДОМСТВЕННЫЙ ЦЕНТР ИНЖЕНЕРНЫХ ИЗЫСКАНИЙ» имеет очень большой опыт по выполнению указанных выше работ при реконструкции Днепровского каскада ГЭС и Днестровской ГЭС-1, строительстве Днестровской и Ташлыкской ГАЭС, первоочередных работах по Каневской ГЭС и Каховской ГЭС-2 и другим гидроэнергетическим объектам в Украине и за границей.',
		service: [
			{
				subTitle_ua: 'Інженерно-гідрологічні вишукування',
				subTitle_en: 'Engineering hydrological surveys',
				subTitle_ru: 'Инженерно-гидрологические изыскания',
				subService: [
					{
						name_ua:
							"Тематика гідрологічних досліджень включає комплекс абсолютно різноманітних робіт, починаючи від гідрографічних (промірних) з метою отримання даних про процеси, що відбуваються у водоймах (розмив - замулення), руслових зйомок різних масштабів для розробки гідрологічного обґрунтування та розрахунків у гідротехнічному проектуванні до тривалого моніторингу стану водяних об'єктів.",
						name_en:
							'The hydrological surveys include a complex of completely diverse works, ranging from hydrographic (gauging) in order to obtain data on the processes occurring in water reservoirs (erosion - silting), riverbed surveys of various scales for the development of a hydrological justification and calculations in hydraulic engineering design to long-term monitoring of the water facilities state.',
						name_ru:
							'Тематика гидрологических изысканий включает в себя комплекс совершенно разнообразных работ, начиная от гидрографических (промерных) с целью получения данных о процессах, происходящих в водоемах (размыв - заиление), русловых съемок различных масштабов для разработки гидрологического обоснования и расчетов в гидротехническом проектировании до продолжительного мониторинга состояния водных объектов.',
					},
					{
						name_ua:
							'Використання сучасних приладів, таких як комплект GNSS GS07 (Швейцарія), ехолот HD-Lite, квадрокоптер Phantom 4 RTK, комплект вимірювача швидкості течії Model 002 (Великобританія), дозволяють проводити спостереження (і визначення обсягу стоку) як за найменшими річками з глибинами кілька сантиметрів, так і за найбільшими водосховищами із глибинами в десятки метрів.',
						name_en:
							'The use of modern instruments such as: GNSS GS07 kit (Switzerland), HD-Lite echo depth sounder, Phantom 4 RTK quadcopter, Model 002 current velocity meter kit (Great Britain) allow to observe (and determine the volume of river flow) both the smallest rivers with depths of several centimeters, and the largest reservoirs with depths of dozens of meters.',
						name_ru:
							'Использование современных приборов таких как: комплект GNSS GS07 (Швейцария), эхолот HD-Lite, квадрокоптер Phantom 4 RTK, комплект измерителя скорости течения Model 002 (Великобритания) - позволяют проводить наблюдения (и определение объема стока) как за самыми малыми реками с глубинами в несколько сантиметров, так и за крупнейшими водохранилищами с глубинами в десятки метров. ',
					},
					{
						name_ua:
							"Сучасні комп'ютерні програми дозволяють побудувати 3D-модель, що відображає сучасний стан дна та берегів, а зробивши необхідні розрахунки отримати площі дзеркал та обсяги ставків та водосховищ.",
						name_en:
							'Modern software allows building a 3D model that displays the current state of the bottom and banks, and by making the necessary calculations to obtain the areas of mirrors and the volumes of ponds and reservoirs.',
						name_ru:
							'Современные компьютерные программы позволяют построить 3D модель, отображающую современное состояние дна и берегов, а произведя необходимые расчеты получить площади зеркал и объемы прудов и водохранилищ.',
					},
				],
			},
		],
	},
];
