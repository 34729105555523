import React from 'react';

import statsPhoto1 from '../img/mainStats/main-stats-1.png';
import useStore from '../store/selectors/useStore';
import { getLocaleProperty } from '../utils/getLocaleProperty';

const stats = [
	{
		name_ua: 'Кваліфікованих інженерів',
		name_en: 'Qualified engineers',
		name_ru: 'Квалифицированных инженеров',
		value: '60+',
	},
	{
		name_ua: 'Спостережень за стійкістю КІА на рік',
		name_en: 'Observations over instrumentation stability per year',
		name_ru: 'Наблюдений за устойчивостью КИА в год',
		value: '7000',
	},
	{
		name_ua: 'Власних високоточних геодезичних інструментів',
		name_en: 'Own high-precision surveying instruments',
		name_ru: 'Собственных высокоточных геодезических инструментов',
		value: '9',
	},
	{
		name_ua: 'Власних бурових установок',
		name_en: 'Own drilling rigs',
		name_ru: 'Собственных буровых установок',
		value: '7',
	},
];

interface propsMainStats {
	className?: string;
}

function MainStats({ className }: propsMainStats) {
	const { language } = useStore();

	return (
		<div className={'main-stats container ' + className}>
			<div className="main-stats__body">
				<ul className="main-stats__list">
					{stats.map((item, index) => (
						<li key={index} className="main-stats__item">
							<p>{item.value}</p>
							<p>{getLocaleProperty(item, 'name', language)}</p>
						</li>
					))}
				</ul>
				<div className="main-stats__img">
					<img src={statsPhoto1} alt="" />
				</div>
			</div>
		</div>
	);
}

export default MainStats;
