import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useRef,
	useState,
	useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import { useFocus, useOnClickOutside } from '../hooks';
import { NEWS, VACANCIES, SEARCH } from '../helpers/routes';
import useStore from '../store/selectors/useStore';
import useDebounce from '../hooks/useDebounce';
import useSearch from '../services/service.search';
import { post } from '../typings/search';
import searchIcon from '../img/icons/searchIcon.svg';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';

interface propsSearch {
	className?: string;
	setFocus: Dispatch<SetStateAction<boolean>>;
}

function Search({ className, setFocus }: propsSearch) {
	const { language } = useStore();

	const { getAllSearch } = useSearch();
	const [refSearchInput, setSearchFocus] = useFocus();
	const refForm = useRef<HTMLFormElement>(null);

	const [searchRequest, setSearchRequest] = useState<string>('');
	const [searchResponse, setSearchResponse] = useState<{
		news: post;
		vacancy: post;
	} | null>(null);
	const debouncedValue = useDebounce<string>(searchRequest, 500);

	const { t } = useTranslation();

	const handleSearchFocus = () => {
		setFocus(false);
		setSearchRequest('');
		setSearchResponse(null);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchRequest(e.target.value);
	};

	const handleCancelSearch = () => {
		setSearchRequest('');
	};

	useOnClickOutside(refForm, handleSearchFocus);

	useEffect(() => {
		if (debouncedValue.length) {
			setSearchResponse(null);
			getAllSearch(debouncedValue, language).then((data) => {
				const news = data?.items?.find((post: any) => post.type === 'article');
				const vacancy = data?.items?.find((post: any) => post.type === 'vacancy')

				setSearchResponse({
					news,
					vacancy,
				});
			});
		}
	}, [debouncedValue, language]);

	return (
		<form
			ref={refForm}
			className={`search ${className}`}
			onSubmit={(e) => e.preventDefault()}
		>
			<div className="search__buttons">
				<button type="submit" className="search__button" />
				<div
					className="search__button-input-focus"
					onClick={() => {
						setFocus(true);
						setSearchFocus();
					}}
				/>
			</div>
			<div className="search__input-wrapper">
				<input
					type="search"
					ref={refSearchInput}
					value={searchRequest}
					onChange={handleChange}
					className="search__input"
				/>
				<div
					className={`search__dropdown search__dropdown--${
						!!searchRequest.length ? 'show' : ''
					}`}
					id="search__dropdown"
				>
					<ul>
						{!!searchResponse?.news && (
							<li className="search__item">
								<Link
									to={`${NEWS}/${searchResponse?.news?.slug}`}
									onClick={handleSearchFocus}
								>
									{searchResponse?.news?.title} (
									{t('search.type.news')})
								</Link>
							</li>
						)}
						{!!searchResponse?.vacancy && (
							<li className="search__item">
								<Link
									to={`${VACANCIES}/${searchResponse?.vacancy.slug}`}
									onClick={handleSearchFocus}
								>
									{searchResponse?.vacancy?.title}{' '}
									({t('search.type.vacancies')})
								</Link>
							</li>
						)}
						<li className="search__item">
							<Link
								to={`${SEARCH}?search=${searchRequest}`}
								onClick={handleSearchFocus}
							>
								<img className="search__search-icon" src={searchIcon} alt="" />
								{t('search.search-with')} <strong>"{searchRequest}"</strong>
							</Link>
						</li>
					</ul>
				</div>
			</div>
			<button
				type="reset"
				className="search__button-cancel"
				onClick={() => {
					setSearchFocus();
					handleCancelSearch();
				}}
			/>
		</form>
	);
}

export default Search;
