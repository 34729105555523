import React from 'react';
import ContactSocial from './ContactSocial';
import FooterAbout from './FooterAbout';
import FooterContact from './FooterContact';
import FooterEquipment from './FooterEquipment';
import FooterService from './FooterService';
import FooterTitle from './FooterTitle';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import FooterMarketing from './FooterMarketing';

function Footer() {
	const { t } = useTranslation();

	return (
		<footer className="footer">
			<div className="container">
				<div className="footer__body">
					<FooterContact className="footer__contact">
						<FooterTitle className="footer__title">
							{t('footer.title.first-part')}
							<br />
							{t('footer.title.second-part')}
						</FooterTitle>
					</FooterContact>
					<FooterMarketing className="footer__marketing">
						<FooterTitle className="footer__title">
							{t('footer.marketing.title')}
						</FooterTitle>
					</FooterMarketing>
					<FooterAbout className="footer__about">
						<FooterTitle className="footer__title">
							{t('footer.about.title')}
						</FooterTitle>
					</FooterAbout>
					<FooterEquipment className="footer__equipment">
						<FooterTitle className="footer__title">
							{t('footer.equipment.title')}
						</FooterTitle>
					</FooterEquipment>
					<FooterService className="footer__service">
						<FooterTitle className="footer__title">
							{t('footer.services.title')}
						</FooterTitle>
					</FooterService>
				</div>
				<ContactSocial className="footer__social" />
			</div>
		</footer>
	);
}

export default Footer;
