import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ABOUT, NEWS, VACANCIES } from '../helpers/routes';
import useStore from '../store/selectors/useStore';

import { getLocaleProperty } from '../utils/getLocaleProperty';
import '../utils/i18next';
import { scrollToAnchor } from '../helpers/scrollToAnchor';

const aboutLinks1 = [
	{
		name_ua: 'Керівництво',
		name_en: 'Management',
		name_ru: 'Руководство',
		path: `management`,
	},
	{
		name_ua: 'Структура',
		name_en: 'Structure',
		name_ru: 'Структура',
		path: `structure`,
	},
	{
		name_ua: 'Файли для завантаження',
		name_en: 'Downloads',
		name_ru: 'Файлы для скачивания',
		path: `downloads`,
	},
];

const aboutLinks2 = [
	{
		name_ua: 'Новини',
		name_en: 'News',
		name_ru: 'Новости',
		path: NEWS,
	},
	{
		name_ua: 'Вакансії',
		name_en: 'Career',
		name_ru: 'Вакансии',
		path: VACANCIES,
	},
];

interface propsFooterAbout {
	className?: string;
	children?: ReactNode;
}

function FooterAbout({ className, children }: propsFooterAbout) {
	const { language } = useStore();

	const handleClick = (path: string) => {
		scrollToAnchor(path);
	};

	return (
		<div className={`footer-about ${className}`}>
			<div className="footer-about__body">
				{children}
				<ul className="footer-about__list">
					{aboutLinks1.map((item, index) => (
						<li key={index} className="footer-about__item">
							<Link
								to={ABOUT}
								className="footer-about__link"
								onClick={() => {
									handleClick(item.path);
								}}
							>
								{getLocaleProperty(item, 'name', language)}
							</Link>
						</li>
					))}
					{aboutLinks2.map((item, index) => (
						<li key={index} className="footer-about__item">
							<Link
								to={item.path}
								className="footer-about__link"
								onClick={() => {
									handleClick(item.path);
								}}
							>
								{getLocaleProperty(item, 'name', language)}
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default FooterAbout;
