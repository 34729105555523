import React, { useEffect, useState } from 'react';
import EquipmentSlider from '../components/EquipmentSlider';
import Tabs from '../components/Tabs';
import Title from '../components/Title';

import { tabsEquipment } from '../data/tabs';

import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { useDocumentTitle } from '../hooks';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { useLocation } from 'react-router-dom';

interface propsEquipmentTabs {
	className?: string;
}

function Equipment({ className }: propsEquipmentTabs) {
	const { t } = useTranslation();
	useDocumentTitle(t('equipment.title'));

	const location = useLocation().search;
	const active = new URLSearchParams(location).get('type') || 0;

	const [activeTab, setActiveTab] = useState<number>(Number(active));

	useEffect(() => {
		setActiveTab(Number(active));
	}, [location, active]);

	return (
		<div className={`main-about-tabs ${className}`}>
			<ScrollToTopOnMount />
			<div className="container">
				<Title size="middle" uppercase className="main-about-tabs__title">
					{t('equipment.title')}
				</Title>
				<Tabs
					data={tabsEquipment}
					type="equipment"
					className="main-about-tabs__content"
					activeTab={activeTab}
				/>
				<EquipmentSlider
					images={tabsEquipment[activeTab].slider}
					className="main-about-tabs__slider"
				/>
			</div>
		</div>
	);
}

export default Equipment;
