import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FIRST_PAGE_NUMBER, PAGINATION_ITEMS_PER_VIEW } from '../consts/common';
import ArrowLink from './ArrowLink';

type TPaginationProps = {
	limitPerView?: number;
	maxPage: number;
	onPageChange: (page: number) => void;
	initialCurrentPage?: number;
	isVisible?: boolean;
};

const Pagination = ({
	limitPerView = PAGINATION_ITEMS_PER_VIEW,
	maxPage,
	initialCurrentPage = FIRST_PAGE_NUMBER,
	onPageChange,
	isVisible,
}: TPaginationProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const urlPage = new URLSearchParams(location.search).get('page');

	const isFirstPage = (currentPage: number): boolean => {
		return currentPage === FIRST_PAGE_NUMBER;
	};

	const isLastPage = (currentPage: number, maxPage: number): boolean => {
		return currentPage === maxPage;
	};

	const getPaginationItems = (
		limitPerView: number,
		maxPage: number,
		currentPage: number
	) => {
		if (maxPage <= 1 || limitPerView < 1) {
			return [];
		}
		const diff = maxPage - currentPage;

		if (diff > limitPerView) {
			if (currentPage === 1) ++currentPage;
			return Array(limitPerView)
				.fill(0)
				.map((_, index) => index + currentPage);
		}

		const result = Array(diff)
			.fill(0)
			.map((_, index) => index + currentPage);

		for (let i = 0; i < limitPerView - diff; ++i) {
			result.unshift(currentPage - 1 - i);
		}

		return result;
	};

	const [currentPage, setCurrentPage] = useState<number>(
		urlPage ? +urlPage : initialCurrentPage
	);

	const paginationIndices = useMemo(
		() =>
			getPaginationItems(limitPerView, maxPage, currentPage).filter(
				(pageIndex) => {
					return pageIndex > FIRST_PAGE_NUMBER && pageIndex < maxPage;
				}
			),
		[maxPage, limitPerView, currentPage]
	);

	useEffect(() => {
		onPageChange(currentPage);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [currentPage, navigate, onPageChange]);

	useEffect(() => {
		if (urlPage) {
			setCurrentPage(+urlPage);
		}
	}, [urlPage]);

	if (maxPage === 1 || !isVisible) return <span />;

	return (
		<div className="pagination">
			<button
				type="button"
				onClick={() => {
					if (!isFirstPage(currentPage)) setCurrentPage((state) => state - 1);
				}}
				className="pagination__item"
			>
				<ArrowLink
					color="blue"
					className="pagination__arrow pagination__arrow--prev"
				/>
			</button>
			<button
				type="button"
				className={
					currentPage === FIRST_PAGE_NUMBER
						? 'pagination__item current'
						: 'pagination__item'
				}
				onClick={() => setCurrentPage(FIRST_PAGE_NUMBER)}
			>
				{FIRST_PAGE_NUMBER}
			</button>
			{!isFirstPage(paginationIndices[0] - 1) &&
				maxPage >= PAGINATION_ITEMS_PER_VIEW && (
					<span className="me-2">...</span>
				)}
			{paginationIndices.map((pageIndex) => (
				<button
					key={pageIndex}
					type="button"
					className={
						currentPage === pageIndex
							? 'pagination__item current'
							: 'pagination__item'
					}
					onClick={() => setCurrentPage(pageIndex)}
				>
					{pageIndex}
				</button>
			))}
			{!isLastPage(
				paginationIndices[paginationIndices.length - 1] + 1,
				maxPage
			) &&
				maxPage >= PAGINATION_ITEMS_PER_VIEW && <span>...</span>}
			<button
				type="button"
				className={
					currentPage === maxPage
						? 'pagination__item current'
						: 'pagination__item'
				}
				onClick={() => setCurrentPage(maxPage)}
			>
				{maxPage}
			</button>
			<button
				type="button"
				onClick={() => {
					if (!isLastPage(currentPage, maxPage))
						setCurrentPage((state) => state + 1);
				}}
				className="pagination__item"
			>
				<ArrowLink
					color="blue"
					className="pagination__arrow pagination__arrow--next"
				/>
			</button>
		</div>
	);
};

export default Pagination;
