import { useCallback } from 'react';
import useHttp from './httpService';


const useDocuments = () => {
	const {  request, getAllDoc } = useHttp();
	const _baseUrl = `${process.env.REACT_APP_API_URL}`;

	const getAllDocuments = useCallback(
		( language = 'uk', type: string) => getAllDoc('document', 'GET', null, type,
		{'Accept-Language': `${language}`, 
		'X-Workspace': `mcii`,
		'Access-Control-Allow-Origin': `${_baseUrl}`}),
		[getAllDoc]
	);


	const getAllRegularInfo = useCallback(async () => {
		const data = await request('/documents/get-regular/1');
		return data;
	}, [request]);

	return {
		getAllDocuments,
		getAllRegularInfo,
	};
};

export default useDocuments;
