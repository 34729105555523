import React, { useState } from 'react';

function UpScroll() {
	const [isVisible, setIsVisible] = useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setIsVisible(true);
		} else if (scrolled <= 500) {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	window.addEventListener('scroll', toggleVisible);

	return (
		<button
			onClick={scrollToTop}
			className={isVisible ? 'up-scroll visible' : 'up-scroll'}
		/>
	);
}

export default UpScroll;
