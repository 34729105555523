import React from 'react';
import { Link } from 'react-router-dom';
import { propsNewsList } from '../typings/news';
import { useTranslation } from 'react-i18next';
import '../utils/i18next';
import { imageSrc } from '../helpers/imageSrc';
import { NEWS } from '../helpers/routes';
import useRedirectToItem from '../hooks/useRedirectToItem';

function NewsList({ data }: propsNewsList) {
	const { t } = useTranslation();
	const redirect = useRedirectToItem();
	
	return (
		<ul className="news-list">
			{data.map((item, index) => (
				<li
					key={index}
					className="news-list__item"
					onClick={() => redirect(NEWS, item.slug)}
				>
					<div className="news-list__image">
						<img src={`${imageSrc(item)}`} alt="" />
					</div>
					<div className="news-list__content">
						<span className="news-list__date">
							{item.article.updatedAt
								.slice(0, 10)
								.split('-')
								.reverse()
								.join('-')
								.replaceAll('-', '.')}
						</span>
						<h3 className="news-list__title">{item.title}</h3>
						<div
							className="news-list__description"
							contentEditable="false"
							dangerouslySetInnerHTML={{
								__html: item?.body,
							}}
						/>
						<div
							className="news-list__button button-link button-link--blue"
						>
							{t('news.buttons.read-more')}
						</div>
					</div>
				</li>
			))}
		</ul>
	);
}

export default NewsList;
