import React from "react";

interface propsArrowLink {
	className?: string;
	onClick?: () => void;
	color?: "blue" | "white";
}

function ArrowLink({ className, onClick, color }: propsArrowLink) {
	return (
		<div
			onClick={onClick}
			className={`arrow-link arrow-link--${color} ${className}`}
		>
			<span></span>
		</div>
	);
}

export default ArrowLink;
