import React from "react";

interface propsManagementInfo {
	className?: string;
	photo?: string;
	fullName: string;
	position: string;
}

function ManagementInfo({
	className,
	photo,
	fullName,
	position,
}: propsManagementInfo) {
	return (
		<li className={`management-info ${className}`}>
			<div className="management-info__image">
				{photo !== "" && <img src={photo} alt="" />}
			</div>
			<div className="management-info__text">
				<h5 className="management-info__full-name">{fullName}</h5>
				<h6 className="management-info__position">{position}</h6>
			</div>
		</li>
	);
}

export default ManagementInfo;
