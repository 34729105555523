import React from 'react'
import Management from './Management'

interface propsMainManagement {
	className?: string,
}

function MainManagement({ className }: propsMainManagement) {
	return (
		<div className={`${className} main-management`}>
			<Management titleSize="middle" />
		</div>
	)
}

export default MainManagement
